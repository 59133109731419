<template>
  <v-container>
    <recording-table v-bind="props" :update-route="true"></recording-table>
  </v-container>
</template>
<script>

import RecordingTable from '@/components/RecordingTable'
import {recordingTable} from '@/tables'

export default {
  components: {
    RecordingTable
  },
  data () {
    return {
      props: recordingTable
    }
  }
}
</script>