var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.results)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results.results,"options":_vm.options,"server-items-length":_vm.results.count,"loading":_vm.isLoading,"footer-props":{itemsPerPageOptions: [5, 25, 50, 100]}},on:{"update:options":[function($event){_vm.options=$event},function($event){_vm.options = _vm.options}]},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: (_vm.routerNamespace + ".detail"), params: {id: item.id}}}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.user.client.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: "clients.detail", params: {id: item.user.client.id}}}},[_vm._v(" "+_vm._s(item.user.client.name)+" ")])]}},{key:"item.creation_date",fn:function(ref){
var item = ref.item;
return [_c('time',{attrs:{"title":item.creation_date}},[_vm._v(" "+_vm._s(_vm.formatDate(item.creation_date))+" ")])]}},{key:"item.bytes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.humanFileSize(item.bytes))+" ")]}},{key:"top",fn:function(){return [_c('data-table-top',{ref:"top",attrs:{"form-title":_vm.formTitle,"item":_vm.editedItem,"entity-name":_vm.entityName,"entity-name-plural":_vm.entityNamePlural,"can-create":_vm.canCreate,"can-edit":_vm.canEdit,"query":_vm.filters.q},on:{"update:query":function($event){return _vm.$set(_vm.filters, "q", $event)},"save":function($event){return _vm.save($event)},"close":function($event){return _vm.close()}}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('download-link',{staticClass:"mr-2",attrs:{"api-url":("admin/recordings/" + (item.id) + "/download"),"filename":((item.user.client.name) + "-" + (item.id) + "-" + (item.transformation.name) + ".wav"),"icon-attrs":{small: true},"title":"Download Audio file"}})]}}],null,false,1283324945)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }