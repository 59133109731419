<template>
  <v-data-table
    :headers="headers"
    :items="results.results"
    :options.sync="options"
    :server-items-length="results.count"
    v-if="results"
    :loading="isLoading"
    :footer-props="{itemsPerPageOptions: [5, 25, 50, 100]}"
    @update:options="options = options"
  >
    <template v-slot:item.id="{ item }">
      <router-link :to="{name: `${routerNamespace}.detail`, params: {id: item.id}}">
        {{ item.id }}
      </router-link>
    </template>
    <template v-slot:item.user.client.name="{ item }">
      <router-link :to="{name: `clients.detail`, params: {id: item.user.client.id}}">
        {{ item.user.client.name }}
      </router-link>
    </template>
    <template v-slot:item.creation_date="{ item }">
      <time :title="item.creation_date">
        {{ formatDate(item.creation_date) }}
      </time>
    </template>
    <template v-slot:item.bytes="{ item }">
      {{ humanFileSize(item.bytes) }}
    </template>
    <template v-slot:top>
      <data-table-top
        :form-title="formTitle"
        :item="editedItem"
        :entity-name="entityName"
        :entity-name-plural="entityNamePlural"
        :can-create="canCreate"
        :can-edit="canEdit"
        :query.sync="filters.q"
        @save="save($event)"
        @close="close()"
        ref="top"
      >
       
      </data-table-top>
    </template>
    <template v-slot:item.actions="{ item }">

      <download-link
        :api-url="`admin/recordings/${item.id}/download`"
        :filename="`${item.user.client.name}-${item.id}-${item.transformation.name}.wav`"
        :icon-attrs="{small: true}"
        class="mr-2"
        title="Download Audio file"></download-link>
    </template>
  </v-data-table>
</template>

<script>

import DataTable from "./DataTable"
import DataTableTop from "./DataTableTop"
import DownloadLink from './DownloadLink'

import {formatDate} from '@/time'
import {humanFileSize} from '@/utils'

export default {
  mixins: [DataTable],
  components: {
    DataTableTop,
    DownloadLink,
  },
  data () {
    return {
      humanFileSize,
    }
  },
  methods: {
    formatDate
  }
}
</script>